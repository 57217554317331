<template>
  <div>
    <div class="container">
      <img src="@/assets/img/course/sj_StudyAbroadGuide/banner.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroadGuide/1.jpg" alt="" />
	  <img src="@/assets/img/course/sj_StudyAbroadGuide/2.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "consult",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.container {
	padding-top:54px;
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>